import logo from './logo.svg';
import './App.css';
import ProjectOverview from './components/ProjectOverview/ProjectOverview';
import React, { useState, useEffect } from 'react';
import Web3 from 'web3'
import SimpleStorageContract from "./contracts/SimpleStorage.json";
import ResumeOverview from './components/ResumeOverview/ResumeOverview';



function App() {

  return (
    <div className="App">
      <ProjectOverview />

      <div className="py-8">
        <ResumeOverview />
      </div>

    </div >

  )
}

export default App;
