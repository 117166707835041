
function ResumeOverview() {
    return (

        <div>
            <h2 className="text-3xl">
                Introducing: Retro Resumes
            </h2>

            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-2xl sm:max-w-xl sm:mx-auto md:mt-5">
                Get the chance to mint your own random resume.  To be used later to apply for random jobs in the metaverse.  Join our Discord to learn more.
                Come grab a resume, your past is your future.
            </p>

        </div >

    );

}

export default ResumeOverview