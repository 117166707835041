import discord from '../img/discord.svg'

function DiscordButton() {
    return (
        <a href="https://discord.gg/sKrJKnqePQ" className="inline-block text-center bg-indigo-600 border border-transparent rounded-md py-2 px-4  hover:bg-indigo-700">
            <img src={discord} />
        </a>
    );
}

export default DiscordButton