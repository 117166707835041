import DiscordButton from "../../common_ui/DiscordButton";
import MetaMaskButton from "../../common_ui/MetaMaskButton";
import logo from "../../img/comput_practice.png";

function ProjectOverview() {
    return (
        <div>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div>
                    <div className="py-8">
                        <img className="mx-auto" height="120" width="120" src={logo} />
                    </div>


                    <h1 className="gaiafont text-4xl tracking-tight font-extrabold  sm:text-5xl md:text-6xl">
                        <span className="">Lounge Bureau</span>{' '}
                    </h1>

                    <div className="pixelcursive text-4xl py-8 font-bold">
                        <span className="bg-clip-text bg-gradient-to-r from-indigo-300 to-green-400 xl:inline text-transparent">
                            Coming Soon
                        </span>
                    </div>

                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center ">
                        <DiscordButton />

                    </div>

                </div>
            </main>


        </div>

    );

}

export default ProjectOverview